import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";

import DirectorCard from "./DirectorCard";
import InstructorCard from "./InstructorCard";

const director = {
  name: "박신기",
  position: "대표원장",
  photoUrl: "/logo512.png",
  resume: [
    "컴퓨터비전 AI 회사 AIT 스튜디오 대표 (벤처기업 인증)",
    "서울대 데이터사이언스 석사",
    "현대모비스 자율주행/임베디드 개발",
    "KAIST 기계공학과 졸업",
    "경기북과학고 조기졸업",
    "자율주행 특허 9건(2건 등록), AI키오스크 특허 4건(2건 등록)",
  ],
};

const instructors = [
  {
    name: "박신기",
    position: "대표원장",
    photoUrl: "/profile_PSG.jpeg",
    resume: [
      "컴퓨터비전 AI 회사 AIT 스튜디오 대표",
      "서울대 데이터사이언스 석사",
      "현대모비스 자율주행/임베디드 개발",
      "KAIST 기계공학과 졸업",
      "경기북과학고등학교 조기졸업",
      // "자율주행 특허 9건(2건 등록), AI키오스크 특허 4건(2건 등록)",
    ],
  },
  {
    name: "김예진",
    position: "부원장",
    photoUrl: "/profile_KYJ.png",
    resume: [
      "AIT 코딩학원 교육 설계 및 컨설팅",
      "에듀테크 기업 STEAM교육콘텐츠 연구 및 개발",
      "한국교육개발원 학교교육연구소 연구원",
      "한국교육공학회, 교육정보미디어학회, 컴퓨터교육학회 학술지 등 논문 게재",
      "이화여자대학교 교육공학 석사",
      "이화여자대학교 사범대 조기졸업",
    ],
  },
  {
    name: "주희민",
    position: "멘토",
    photoUrl: "/profile_JHM.png",
    resume: [
      "AIT 스튜디오 개발자",
      "서강대학교 컴퓨터공학 전공",
      "한국HCI학회, 컴퓨터교육학회 학술지 논문 게재",
      "경기북과학고등학교 졸업",
      "2017년도 전국과학전람회 특상 수상",
    ],
  },
  // {
  //   name: "명하준",
  //   position: "멘토",
  //   photoUrl: "/profile_MHJ.png",
  //   resume: [
  //     "AIT 스튜디오 수석개발자",
  //     "고려대학교 응용수학 전공",
  //     "경기북과학고등학교 졸업",
  //     "아두이노 동아리 SADA 정회원",
  //     "intel ISEF 한국 국가대표",
  //   ],
  // },
  // {
  //   name: "김연호",
  //   position: "멘토",
  //   photoUrl: "/profile_KYH.png",
  //   resume: [
  //     "연세대 천문우주학과 석사과정",
  //     "GIST 물리 전공",
  //     "경기북과학고등학교 졸업",
  //     "국제 천문 및 천체물리 올림피아드 국가대표",
  //     "KOFAC 과학 커뮤니케이터 역임",
  //   ],
  // },
  {
    name: "정찬우",
    position: "멘토",
    photoUrl: "/profile_JCW.jpeg",
    resume: [
      "로봇 스타트업 창립멤버",
      "하이스쿨 아두이노 저자",
      "경기북과학고 졸업",
      "아두이노 동아리 SADA 정회원",
      "삼성 휴먼테크 논문대상 은상 수상",
    ],
  },
  {
    name: "최호재",
    position: "멘토",
    photoUrl: "/profile_CHJ.jpeg",
    resume: ["경기북과학고 졸업"],
  },
  // {
  //   name: "정지윤",
  //   position: "멘토",
  //   photoUrl: "/profile_JJY.png",
  //   resume: [
  //     "AIT 코딩학원 교육 설계 및 컨설팅",
  //     "지속가능발전교육, 미래역량중심교육 기관 근무",
  //     "에듀테크 기업 STEAM교육콘텐츠 연구 및 개발",
  //     "목동 사과나무 학원 외고 전담 강사",
  //     "이화여자대학교 사범대 졸업",
  //     "대일외국어고등학교 졸업",
  //     "MLFN 국제학교 졸업",
  //   ],
  // },
];

export default function AcademyInstructors(props) {
  return (
    <Box className="layoutSubPage instructors">
      {/* <Box className="directorCardWrapper">
        <DirectorCard
          photoUrl={director.photoUrl}
          name={director.name}
          position={director.position}
          resume={director.resume}
        />
      </Box> */}
      <Box className="instructorTitle">
        <Box className="title">
          <Box className="titleBox">
            <Typography>강사진</Typography>
            <Box />
          </Box>
        </Box>
      </Box>

      <Grid container className="instructorCardWrapper">
        {instructors.map((item, idx) => (
          <Grid item key={idx} xs={12} sm={6} md={4}>
            <InstructorCard
              photoUrl={item.photoUrl}
              name={item.name}
              position={item.position}
              resume={item.resume}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
