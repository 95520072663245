import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function SymbolContent(props) {
  return (
    <Box className="symbolContent">
      <img className="symbol" src={props.symbol} alt="symbol img" />
      <Box className="captionContent">
        <Typography className="caption">{props.firstCaption}</Typography>
        <Typography className="caption">{props.secondCaption}</Typography>
      </Box>
    </Box>
  );
}

export default SymbolContent;
