import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { useEffect, useRef } from "react";

export default function FAQCard(props) {
  const FAQCardRef = useRef();

  useEffect(() => {
    if (props.selected === props.idx) {
      FAQCardRef.current.classList.add("selected");
    } else {
      FAQCardRef.current.classList.remove("selected");
    }
  }, [props.selected]);

  return (
    <Box className="FAQCard" ref={FAQCardRef}>
      <Box
        className="titleWrapper"
        onClick={() => {
          props.selected === props.idx
            ? props.selectedhandler(null)
            : props.selectedhandler(props.idx);
        }}
      >
        <Typography>{props.question}</Typography>
      </Box>
      <Box
        className="captionWrapper"
        style={{
          "--answer-length": props.answer.length,
        }}
      >
        {props.answer.map((item, idx) => (
          <Typography key={idx}>{item}</Typography>
        ))}
      </Box>
    </Box>
  );
}
