import ImageGallery from "react-image-gallery";

import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";

import "./image-gallery.css";

const imgList = [
  {
    original: "/spaceSlide_10.jpg",
    thumbnail: "/spaceSlide_10.jpg",
    // originalHeight: "600px",
    description: "Lobby",
  },
  {
    original: "/spaceSlide_2.jpg",
    thumbnail: "/spaceSlide_2.jpg",
    // originalHeight: "600px",
    description: "AIT LAB",
  },
  {
    original: "/spaceSlide_7.jpg",
    thumbnail: "/spaceSlide_7.jpg",
    // originalHeight: "600px",
    description: "AIT LAB",
  },
  {
    original: "/spaceSlide_3.jpg",
    thumbnail: "/spaceSlide_3.jpg",
    // originalHeight: "600px",
    description: "Room 302",
  },
  {
    original: "/spaceSlide_5.jpg",
    thumbnail: "/spaceSlide_5.jpg",
    // originalHeight: "600px",
    description: "Room 302",
  },
  {
    original: "/spaceSlide_4.jpg",
    thumbnail: "/spaceSlide_4.jpg",
    // originalHeight: "600px",
    description: "Room 302",
  },
  {
    original: "/spaceSlide_1.jpg",
    thumbnail: "/spaceSlide_1.jpg",
    // originalHeight: "600px",
    description: "Room 302",
  },
  {
    original: "/spaceSlide_6.jpg",
    thumbnail: "/spaceSlide_6.jpg",
    // originalHeight: "600px",
    description: "Room 303",
  },
];

export default function SpaceGallery() {
  return (
    <Box className="layoutSubPage spaceGallery">
      <Box className="title">
        <Box className="titleBox">
          <Typography>학습공간 소개</Typography>
          <Box />
        </Box>
      </Box>
      <ImageGallery items={imgList} showPlayButton={false} />
    </Box>
  );
}
