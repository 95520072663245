import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { useEffect, useRef } from "react";

export default function CourseCard(props) {
  const courseCardRef = useRef();

  useEffect(() => {
    if (props.selected === props.idx) {
      courseCardRef.current.classList.add("selected");
    } else {
      courseCardRef.current.classList.remove("selected");
    }
  }, [props.selected]);

  return (
    <Box className="courseCard" ref={courseCardRef}>
      <Box
        className="titleWrapper"
        onClick={() => {
          props.selected === props.idx
            ? props.selectedhandler(null)
            : props.selectedhandler(props.idx);
        }}
      >
        <Typography>{props.title}</Typography>
      </Box>
      <Box className="courseWrapper">
        <Box className="imgWrapper">
          <img
            src={props.img}
            alt="코스 상세 이미지"
            style={{ width: "90%", objectFit: "cover" }}
          />
        </Box>
        <Box className="captionWrapper">
          {props.caption.map((item) => (
            <Typography key={item}>{item}</Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
