import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { useRef, useCallback, useEffect } from "react";

import ClassCard from "./ClassCard";

export default function ClassGroupCard(props) {
  const classGroupCardRef = useRef();
  let observer;

  const handleScroll = useCallback(([entry]) => {
    if (entry.isIntersecting) {
      props.selectedhandler(props.id);
      // console.log(props.id);
    }
  }, []);

  useEffect(() => {
    classGroupCardRef.current.classList.add(props.id);
  }, []);

  useEffect(() => {
    if (classGroupCardRef.current) {
      observer = new IntersectionObserver(handleScroll, { threshold: 0.7 });
      observer.observe(classGroupCardRef.current);

      return () => observer && observer.disconnect();
    }
  }, [handleScroll]);

  return (
    <Box className="classGroupCard" ref={classGroupCardRef}>
      <Box className="titleBox">
        <Typography className="groupName">{props.groupName}</Typography>
        <Box className="captionBox">
          {props.caption.map((item, idx) => (
            <Typography key={idx} className="caption">
              {item}
            </Typography>
          ))}
        </Box>
      </Box>
      <Box className="classCardContainer">
        {props.subClass.map((item, idx) => (
          <ClassCard
            key={idx}
            img={item.img}
            groupName={props.groupName}
            difficulty={item.difficulty}
            recommend={item.recommend}
            type={item.type}
            caption={item.caption}
          />
        ))}
      </Box>
    </Box>
  );
}
