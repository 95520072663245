import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import CourseCard from "./CourseCard";
import CourseGroupCard from "./CourseGroupCard";

const elementaryCourseList = [
  {
    title: "코딩이 처음인데, 적성에 맞을까요?",
    caption: [
      "코딩을 처음 배우는 초등학생에게는 코딩 어드벤처 코스를 추천합니다.",
      "비교적 쉬운 블록코딩을 통해 컴퓨터과학적 사고와 알고리즘의 원리를 자연스럽게 익힐 수 있는 코스입니다.",
      "코딩으로 문제를 해결하고 무언가를 창작하는 활동에 흥미를 느꼈다면 본격적으로 텍스트 코딩으로 넘어갈 준비가 되었다고 볼 수 있어요.",
    ],
    img: "/curriculum_elementary_codingAdventure.png",
  },
  {
    title: "스크래치는 알아요. 이제 본격적으로 배워보고 싶어요.",
    caption: [
      "정규교과나 방과후학교에서 스크래치를 충분히 배운 학생에게는 소프트웨어 창작 코스를 추천합니다.",
      "블록코딩으로 앱의 화면을 구성하고 기능을 구현해보면서 기획한 것을 실제 서비스로 만드는 경험을 할 수 있어요. 보다 다양하고 정교한 서비스를 만들기 위해 텍스트 코딩도 배웁니다.",
    ],
    img: "/curriculum_elementary_softwareCreation.png",
  },
  {
    title: "이것저것 만들고 조립하고 발명하는 활동을 좋아해요.",
    caption: [
      "코딩으로 하드웨어를 다양하게 조작해보는 로봇 메이커 코스를 추천합니다.",
      "코딩을 알면 하드웨어를 보다 다양하게 제어할 수 있습니다. 같은 동작을 단순 반복하는 로봇이 아니라 알고리즘을 기반으로 입력에 따라 다른 동작을 출력하도록 만들 수 있습니다.",
      "만들기를 좋아하는 친구라면 로봇 메이커 코스로 코딩에 대한 흥미와 자신감을 키워줄 수 있어요. 코딩으로 하드웨어를 자유롭게 제어해보며 피지컬 컴퓨팅에 대한 이해를 높입니다.",
    ],
    img: "/curriculum_elementary_robotMaker.png",
  },
  {
    title: "블록코딩은 마스터했어요.",
    caption: [
      "블록코딩에 익숙하고 블록코딩을 통한 다양한 창작활동도 모두 경험했다면 다음은 텍스트 코딩으로 넘어갈 차례입니다. 거부감 없이 자연스럽게 텍스트 코딩을 배울 수 있는 텍스트 브릿지 코스를 추천합니다.",
      "텍스트 브릿지 코스를 통해 파이썬 언어와 기초 문법을 배우고 문제를 해결하는 알고리즘에 대해 익힙니다.",
    ],
    img: "/curriculum_elementary_textBridge.png",
  },
];

const middleHighCourseList = [
  {
    title: "코딩이 처음인데, 적성에 맞을까요?",
    caption: [
      "코딩을 처음 배우는 중고등학생에게는 코딩 맛보기 코스를 추천합니다.",
      "비교적 쉬운 블록코딩을 이용해 앱과 게임을 개발해보며 소프트웨어가 작동하는 원리를 자연스럽게 익힙니다.",
      "앱인벤터 과정으로 코딩에 대해 어느정도 익숙해졌다면 간단한 텍스트 코딩을 배우고 하드웨어를 제어해봐요. 프로그래밍으로 구현할 수 있는 다양한 기능을 탐색하다보면 진로에 대해 넓게 생각할 수 있는 기회가 될 거예요.",
    ],
    img: "/curriculum_middleHigh_codeTasting.png",
  },
  {
    title: "소프트웨어 개발에 관심이 있어요.",
    caption: [
      "앱이나 웹, 게임 등을 개발하는 일에 관심이 있다면 앱 개발자 코스를 추천합니다.",
      "블록코딩으로 앱의 화면을 구성하고 기능을 구현해보면서 기획한 것을 실제 서비스로 만드는 경험을 할 수 있어요. 보다 다양하고 정교한 서비스를 만들기 위해 텍스트 코딩도 배웁니다.",
    ],
    img: "/curriculum_middleHigh_appDeveloper.png",
  },
  {
    title: "발명과 과학실험, 피지컬 컴퓨팅에 관심있어요.",
    caption: [
      "코딩으로 하드웨어를 다양하게 조작해보는 발명가 코스를 추천합니다.",
      "코딩을 알면 하드웨어를 보다 다양하게 제어할 수 있습니다. 같은 동작을 단순 반복하는 로봇이 아니라 알고리즘을 기반으로 입력에 따라 다른 동작을 출력하도록 만들 수 있습니다.",
      "코딩으로 하드웨어를 자유롭게 제어해보며 피지컬 컴퓨팅에 대한 이해를 높이고 과학실험에 코딩을 접목해서 더욱 수준 높은 결과를 도출할 수 있어요.",
    ],
    img: "/curriculum_middleHigh_inventor.png",
  },
  {
    title: "텍스트 코딩을 본격적으로 배우고 싶어요.",
    caption: [
      "텍스트 코딩을 본격적으로 경험하고 싶다면 파이썬 브릿지 코스를 추천합니다.",
      "파이썬 브릿지 코스는 가장 대중적으로 사용되는 파이썬의 문법과 알고리즘을 익히고 다양한 프로젝트를 수행하는 코스입니다.",
    ],
    img: "/curriculum_middleHigh_pythonBridge.png",
  },
  {
    title: "컴퓨터 관련 전공에 진학하고 싶어요.",
    caption: [
      "컴퓨터공학 관련 진로를 생각하고 있다면 프로그래밍 언어를 전문적으로 학습하는 베스트 프로그래머 코스를 추천합니다.",
      "베스트 프로그래머 코스에서는 대중적이면서 활용 범위가 넓은 파이썬과 현장에서 전문가들이 필수적으로 사용하는 C언어를 모두 배웁니다.",
    ],
    img: "/curriculum_middleHigh_bestProgrammer.png",
  },
];

export default function Courses(props) {
  return (
    <Box className="layoutSubPage courses">
      <CourseGroupCard
        className="elementary"
        title="초등 추천 코스"
        caption={[
          "초등학년 시기의 코딩은 간단한 학습 활동을 통해 컴퓨터과학적 사고에 익숙해지는 것을 목표로 합니다.",
          "기본적으로는 초등 정규교과인 정보 과목에 대한 소양을 기르고 더 나아가 컴퓨터공학 관련 진로 적성을 파악합니다.",
        ]}
        courseList={elementaryCourseList}
        selectedhandler={props.selectedhandler}
      />
      <CourseGroupCard
        className="middleHigh"
        title="중고등 추천 코스"
        caption={[
          "중・고등학교 시기는 학생의 적성과 목표에 따라 학습의 방향을 다르게 접근할 수 있습니다.",
          "기초 소양을 기르는 것이 목적이라면, 코딩 언어와 다양한 프로젝트를 경험합니다.",
          "컴퓨터공학 관련 진로를 본격적으로 탐색한다면, 적성에 맞는 개발분야를 보다 심도있게 탐색하고 대입 포트폴리오 및 코딩대회 출품 준비를 병행합니다.",
        ]}
        courseList={middleHighCourseList}
        selectedhandler={props.selectedhandler}
      />
    </Box>
  );
}
