import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function InstructorCard(props) {
  return (
    <Box className="instructorCard">
      <Box className="imgBox">
        <img src={props.photoUrl} alt="logo" style={{ width: "100%" }} />
      </Box>
      <Box className="profileBox">
        <Box className="nameBox">
          <Box className="nameWrapper">
            <Typography className="name">{props.name}</Typography>
            <Typography className="position">{props.position}</Typography>
          </Box>
        </Box>
        <Box className="resumeBox">
          {props.resume.map((item, idx) => (
            <Typography className="resume" key={idx}>
              {item}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default InstructorCard;
