import * as React from "react";
import Box from "@mui/material/Box";
import SymbolContent from "./SymbolContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const symbolItems = [
  {
    symbol: "/academy_symbol_icon (0).png",
    firstCaption: "생각을",
    secondCaption: "크게 열어요.",
    scale: 3,
  },
  {
    symbol: "/academy_symbol_icon (1).png",
    firstCaption: "작은 부분도",
    secondCaption: "생각해요.",
    scale: 2,
  },
  {
    symbol: "/academy_symbol_icon (3).png",
    firstCaption: "나누어",
    secondCaption: "생각해요.",
    scale: 3,
  },
  {
    symbol: "/academy_symbol_icon (2).png",
    firstCaption: "코딩은",
    secondCaption: "모두에게 공평해요.",
    scale: 1,
  },
  {
    symbol: "/academy_symbol_icon (5).png",
    firstCaption: "아이디어를",
    secondCaption: "더해요.",
    scale: 2,
  },
  {
    symbol: "/academy_symbol_icon (4).png",
    firstCaption: "생각을",
    secondCaption: "마무리해요.",
    scale: 3,
  },
];

function Symbols(props) {
  const navigate = useNavigate();

  return (
    <Box className="symbols">
      {symbolItems.map((item) => (
        <SymbolContent
          key={item.symbol}
          symbol={item.symbol}
          firstCaption={item.firstCaption}
          secondCaption={item.secondCaption}
          scale={item.scale}
        />
      ))}
      <Box className="captionBox">
        <Typography className="title">우리의 교육철학</Typography>
        <Typography className="text">
          석기시대는 돌이 없어서 끝난 것이 아닙니다. 이제 AI라는 도구와 함께
          우리는 새로운 세상을 살게 되었습니다.
        </Typography>
        <Typography className="text">
          AIT는 AI 시대에 적합한 인재, AI 트렌드 리더를 길러냅니다.
        </Typography>
        <Button
          className="blackButton"
          disableRipple
          onClick={() => {
            navigate("/about");
          }}
        >
          자세히 보기
        </Button>
      </Box>
    </Box>
  );
}

export default Symbols;
