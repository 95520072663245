import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useState, useEffect, useRef } from "react";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircleIcon from "@mui/icons-material/Circle";

const styles = {
  withAnimated: {
    transition: "transform 0.4s ease-in-out",
  },
  withoutAnimated: {
    transition: "",
  },
};

function PageSlider(props) {
  const sliderLength = props.length;
  const [currIndex, setCurrIndex] = useState(0);
  const [isAnimated, setIsAnimated] = useState(true);
  // const bgVideoRef = useRef();

  // useEffect(() => {
  //   bgVideoRef.current?.load();
  // }, [currVideo]);

  const sliderToNext = () => {
    const newIndex = currIndex + 1;

    if (newIndex >= sliderLength) {
      setCurrIndex(newIndex);
      setIsAnimated(false);
      moveToNthSlide(0);
    } else {
      setCurrIndex(newIndex);
    }
    setIsAnimated(true);
  };
  const sliderToPrev = () => {
    const newIndex = currIndex - 1;

    if (newIndex < 0) {
      setCurrIndex(newIndex);
      setIsAnimated(false);
      moveToNthSlide(sliderLength - 1);
    } else {
      setCurrIndex(newIndex);
    }
    setIsAnimated(true);
  };

  const moveToNthSlide = (n) => {
    setTimeout(() => {
      setIsAnimated(false);
      setCurrIndex(n);
    }, 400);
  };

  const autoPlayRef = useRef();

  useEffect(() => {
    autoPlayRef.current = sliderToNext;
  });

  useEffect(() => {
    const play = () => {
      autoPlayRef.current();
    };
    const interval = setInterval(play, props.autoPlay * 1000);
    return () => clearInterval(interval);
  }, []);

  function SlideNavigator(props) {
    return (
      <Box className="slideNavigator">
        {[...new Array(props.length)].map((item, index) => {
          return (
            <Button
              disableRipple
              key={index}
              className={index === props.currIndex ? "selected" : ""}
              onClick={() => {
                moveToNthSlide(index);
              }}
            >
              <CircleIcon className="icon" />
            </Button>
          );
        })}
      </Box>
    );
  }

  return (
    <Box className="pageSlider">
      <Box className="slider">
        <Box
          className="slideWrapper"
          sx={{
            transform: `translateX(-${currIndex * 100 + 100}vw)`,
            ...(isAnimated ? styles.withAnimated : styles.withoutAnimated),
          }}
        >
          {props.children}
        </Box>
        {/* <Button
          className="controller left"
          disableRipple
          onClick={() => {
            sliderToPrev();
          }}
        >
          <ArrowBackIosNewIcon className="Icon" />
        </Button>
        <Button
          className="controller right"
          disableRipple
          onClick={() => {
            sliderToNext();
          }}
        >
          <ArrowForwardIosIcon className="Icon" />
        </Button> */}
        {/* <SlideNavigator length={sliderLength} currIndex={currIndex} /> */}
      </Box>
    </Box>
  );
}
export default PageSlider;
