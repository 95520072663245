import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ScrollToTop from "./ScrollToTop";

function InstructorSlide(props) {
  const navigate = useNavigate();
  const { window } = props;

  return (
    <>
      <Box className="background">
        <video autoPlay loop muted playsInline className="bgVideo">
          <source src={"/wandering-blub.mp4"} type="video/mp4" />
        </video>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            position: "absolute",
          }}
        />
      </Box>
      <Box className="slide instructor">
        <Box className="captionBox">
          <Box className="titleBox">
            <Typography className="title">과학고 출신</Typography>
            <Typography className="title">강사진</Typography>
          </Box>
          <Box className="contentBox">
            <Typography className="content">
              교육공학 전문가와 현업 개발자들이 만든 체계적인 교수설계,
              과학고/외고 출신 강사진의 지도로 완성하다.
            </Typography>
            <Button
              disableRipple
              className="arrowButton"
              onClick={() => {
                navigate("/about", {
                  state: { scroll_id: "instructors" },
                });
                ScrollToTop();
              }}
            >
              <ArrowForwardIcon
                className="icon"
                sx={{
                  color: "#FFF",
                  fontSize: "40px",
                }}
              />
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default InstructorSlide;
