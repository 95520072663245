import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useRef, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

const galleryUrls = [
  ["/IMG_4357.png", "/IMG_4350.png"],
  ["/IMG_4376.png", "/IMG_4352.png"],
  ["/IMG_4380.png", "/IMG_4369.png"],
];

function CardGallery() {
  const cardGallery = useRef();

  const handleScroll = useCallback(([entry]) => {
    if (entry.isIntersecting) {
      cardGallery.current.classList.add("onViewport");
    } else {
      cardGallery.current.classList.remove("onViewport");
    }
  }, []);

  useEffect(() => {
    let observer;

    if (cardGallery.current) {
      observer = new IntersectionObserver(handleScroll, { threshold: 0 }); //0.7 = 70% 노출 시 이벤트 실행
      observer.observe(cardGallery.current);

      return () => observer && observer.disconnect();
    }
  }, [handleScroll]);

  return (
    <Box className="cardGallery" ref={cardGallery}>
      {galleryUrls.map((item, outer_index) => {
        return (
          <Box className="cardGroup" key={item}>
            {galleryUrls[outer_index].map((item, inner_index) => {
              return (
                <Box
                  className="card"
                  key={item}
                  sx={{
                    "--count-index": outer_index * 2 + inner_index,
                  }}
                >
                  <img src={item} alt="logo" />
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
}

function LearningSpace() {
  const navigate = useNavigate();

  return (
    <Box className="learningSpace">
      <CardGallery />
      <Box className="captionBox">
        <Box className="titleBox">
          <Typography className="title">아카데미 공간소개</Typography>
        </Box>
        <Box className="contentBox">
          <Box className="logoBox">
            <img
              className="desker"
              src={"/desker_logo.png"}
              alt="desker logo"
            />
            <Typography className="ampersand">&</Typography>
            <img className="odd33" src={"/odd33_logo.png"} alt="odd33 logo" />
          </Box>
          <Box className="captionWrapper desker">
            <Typography className="caption">잘나가는 스타트업 감성</Typography>
            <img
              className="desker"
              src={"/desker_logo.png"}
              alt="desker logo"
            />
            <Typography className="caption">책상과</Typography>
          </Box>
          <Box className="captionWrapper odd33">
            <img className="odd33" src={"/odd33_logo.png"} alt="odd33 logo" />
            <Typography className="caption">
              의 세련된 인테리어로 탄생한
            </Typography>
          </Box>

          <Box className="captionWrapper ait">
            {/* <img
              src={"/academy_logo.png"}
              alt="ait logo"
              style={{ filter: "invert(90%)", height: "70%" }}
            /> */}
            <Typography className="caption">
              아카데미 학습공간을 소개합니다.
            </Typography>
          </Box>
          <Button
            className="blackButton"
            disableRipple
            onClick={() => {
              navigate("/about", {
                state: { scroll_id: "spaceGallery" },
              });
              ScrollToTop();
            }}
            sx={{
              marginTop: "15px",
            }}
          >
            자세히 보기
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default LearningSpace;
