import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import Footer from "../components/Footer";
import FAQs from "../components/FAQs";

export default function FAQPage() {
  return (
    <Box className="pageWrapper">
      <Header selected="FAQ" />
      <Box className="contentWrapper">
        <PageTitle mainTitle="FAQ" subTitle="자주 묻는 질문" />
        <FAQs />
      </Box>
      <Footer />
    </Box>
  );
}
