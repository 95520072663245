import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import TrackPreviewCard from "./TrackPreviewCard";

const trackItems = [
  {
    title: "코딩 맛보기",
    caption: "가볍게 시작하는 코딩의 길",
    period: {
      year: 1,
      month: 6,
    },
    backgroundColor: "#BBB",
    img: "https://littledeep.com/wp-content/uploads/2020/11/snowman-illustration-png-1024x853.png",
  },
  {
    title: "발명 창업가",
    caption: "각종 발명품과 로봇까지",
    period: {
      year: 3,
      month: 0,
    },
    backgroundColor: "#BBB",
    img: "https://upload.wikimedia.org/wikipedia/commons/4/47/PNG_transparency_demonstration_1.png",
  },
  {
    title: "SW 경진대회",
    caption: "웹과 앱으로 그리는 나만의 서비스",
    period: {
      year: 3,
      month: 6,
    },
    backgroundColor: "#BBB",
    img: "https://littledeep.com/wp-content/uploads/2021/05/family-illustration-v2-png-1024x853.png",
  },
  {
    title: "AI",
    caption: "요즘 대세 AI, 스크래치로도 가능?",
    period: {
      year: 4,
      month: 0,
    },
    backgroundColor: "#BBB",
    img: "https://i.pinimg.com/originals/7e/d0/fd/7ed0fdc199db02ba94037cb1a7177349.png",
  },
  {
    title: "CS 올림피아드",
    caption: "취업까지 든든한 알고리즘 학습",
    period: {
      year: 4,
      month: 0,
    },
    backgroundColor: "#BBB",
    img: "https://sb.kaleidousercontent.com/67418/658x756/361846cee7/all-pages-2.png",
  },
];
function TrackPreview(props) {
  const navigate = useNavigate();

  return (
    <Box className="trackPreview">
      <Box className="captionBox">
        <Typography className="title">우리는 무엇이든</Typography>
        <Typography className="title">할 수 있고,</Typography>
        <Typography className="title">될 수 있다.</Typography>
        <Button
          className="blackButton"
          disableRipple
          onClick={() => {
            navigate("/course");
          }}
        >
          자세히 보기
        </Button>
      </Box>
      <Box className="trackCards">
        {trackItems.map((item) => (
          <TrackPreviewCard
            key={item.title}
            title={item.title}
            caption={item.caption}
            period={item.period}
            backgroundColor={item.backgroundColor}
            img={item.img}
          />
        ))}
      </Box>
    </Box>
  );
}

export default TrackPreview;
