import "./App.css";

import { createContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";

import MainPage from "./pages/MainPage.jsx";
import AboutPage from "./pages/AboutPage.jsx";
import CoursePage from "./pages/CoursePage.jsx";
// import Instructors from "../components/Instructors";
import FAQPage from "./pages/FAQPage.jsx";
import ArchivePage from "./pages/ArchivePage.jsx";

function App() {
  return (
    <HashRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" exact element={<MainPage />} />
        <Route path="/about" exact element={<AboutPage />} />
        <Route path="/course" exact element={<CoursePage />} />
        {/* <Route path="/instructors" exact element={<InstructorsPage />} />
        <Route
          path="/instructors/:number"
          exact
          element={<InstructorProfilePage />}
        /> */}
        <Route path="/faq" exact element={<FAQPage />} />
        <Route path="/archive" exact element={<ArchivePage />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
