import Box from "@mui/system/Box";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

import LayoutWithMenu from "../components/LayoutWithMenu";
import AcademyBrandInfo from "../components/AcademyBrandInfo";
import AcademyInstructors from "../components/AcademyInstructors";
import MapContainer from "../components/MapContainer";
import SpaceGallery from "../components/SpaceGallery";

export default function AboutPage() {
  const [selectedMenu, setSelectedMenu] = useState("brandInfo");

  const location = useLocation();
  useEffect(() => {
    if (location.state?.scroll_id) {
      scroller.scrollTo(location.state.scroll_id, {
        offset: -60,
        smooth: true,
        duration: 700,
      });
    }
  }, [location]);

  return (
    <Box className="pageWrapper aboutPage">
      <Header selected="학원소개" />
      <Box className="contentWrapper">
        {/* <LayoutWithMenu
          subPages={[
            { title: "brandInfo", caption: "브랜드 소개" },
            { title: "instructors", caption: "강사진" },
          ]}
          selectedMenu={selectedMenu}
          handler={setSelectedMenu}
        >
          {selectedMenu === "brandInfo" && <AcademyBrandInfo />}
          {selectedMenu === "instructors" && <AcademyInstructors />}
        </LayoutWithMenu> */}
        <img className="brandInfo" src="/brandInfo.png" alt="브랜드 소개" />
        <Box
          sx={{
            padding: "0 10%",
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
          }}
        >
          <AcademyInstructors />
        </Box>
        <Box className="spaceGallery" sx={{ padding: "0 10%" }}>
          <SpaceGallery />
        </Box>
        <Box className="wayToCome" sx={{ padding: "0 10%" }}>
          <MapContainer />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
