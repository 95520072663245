import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import Footer from "../components/Footer";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";

import { useState, useEffect, useCallback } from "react";

import SearchIcon from "@mui/icons-material/Search";
import Link from "@mui/material/Link";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const SearchInput = ({ value = "", setValue = () => {} }) => {
  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: 60,
        borderRadius: 5,
        boxSizing: "border-box",
        paddingLeft: 3,
      }}
    >
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="필요한 자료를 찾아보세요"
        inputProps={{ "aria-label": "필요한 자료를 찾아보세요" }}
        onChange={(val) => {
          // console.log(val.target.value);
          setValue(val.target.value);
        }}
      />
    </Paper>
  );
};

const Tags = ({ tagList, setSelectedTag, selectedTag }) => {
  const selectedColor = "#013b96";
  const unSelectedColor = "#FFF";

  return (
    <Box
      sx={{
        width: "100%",
        height: 50,
        display: "flex",
        alignItems: "center",
        overflow: "scroll",
      }}
    >
      {tagList.map((tag, idx) => (
        <Link
          sx={{ textDecoration: "none", color: "#000" }}
          onClick={() => {
            if (tag === selectedTag) setSelectedTag("전체");
            else setSelectedTag(tag);
          }}
        >
          <Box
            className="tags"
            sx={{
              ml: idx === 0 ? 0 : 1,
              backgroundColor:
                tag === selectedTag ? selectedColor : unSelectedColor,
              color: tag === selectedTag ? unSelectedColor : selectedColor,
              minWidth: "max-content",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 2,
              paddingLeft: 1,
              paddingRight: 1,
              boxSizing: "border-box",
              cursor: "pointer",
              borderColor: "#000",
              borderWidth: 3,
            }}
          >
            <Typography sx={{ fontSize: 22, fontFamily: "pretendard" }}>
              {tag}
            </Typography>
          </Box>
        </Link>
      ))}
    </Box>
  );
};

export default function ArchivePage() {
  const [tagList, setTagList] = useState([]);
  const [selectedTag, setSelectedTag] = useState("전체");
  const [searchInput, setSearchInput] = useState(null);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const [isLogin, setIsLogin] = useState(false); // just for extensive
  const [isTagsOpen, setIsTagsOpen] = useState(true);

  useEffect(() => {
    const data = ["전체", "노트북", "교재", "수업", "과제"];

    const postData = [
      {
        title:
          "AIT코딩학원 수업을 듣기 위해 필요한 노트북 사양 및 제품추천(2023년 2월)",
        link: "https://spiral-nebula-b16.notion.site/AIT-2bb49b40ad4642e99567e00e1ab33175",
        author: "명하준",
        role: "멘토",
        number: 1,
        tag: "노트북",
        date: new Date(2023, 2, 1),
      },
      {
        title: "23년 봄학기 등록 안내(일정, 상담예약, 등등)",
        link: "https://spiral-nebula-b16.notion.site/23-2e36e68fc59e492fb1e992dee0de9f58",
        author: "주희민",
        role: "멘토",
        number: 2,
        tag: "수업",
        date: new Date(2023, 2, 1),
      },
    ];

    setTagList(data);
    setPosts(postData);
  }, []);

  useEffect(() => {
    console.log(tagList);
  }, [tagList]);

  return (
    <Box className="pageWrapper">
      <Header selected="자료실" />
      <Box className="contentWrapper">
        <PageTitle
          mainTitle="NOTICE"
          // subTitle="필요한 게 있으신가요? 아래 자료 목록에서 찾아보세요!"
          subTitle="공지사항"
        />
        <Box
          sx={{
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            paddingBottom: 5,
          }}
        >
          <Box id="boardWrapper">
            <Grid container>
              <Grid item xs={10.5} sm={11} md={11.5}>
                <SearchInput value={searchInput} setValue={setSearchInput} />
              </Grid>
              <Grid item xs={1.5} sm={1} md={0.5}>
                <Link
                  sx={{ textDecoration: "none", color: "#013b96" }}
                  onClick={() => {
                    setIsTagsOpen((prev) => !prev);
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <KeyboardArrowUpIcon
                      id="tagsOpener"
                      sx={{
                        fontSize: 48,
                        transform: isTagsOpen
                          ? "rotate(0deg)"
                          : "rotate(180deg)",
                      }}
                    />
                  </Box>
                </Link>
              </Grid>
              <Grid
                item
                id="tagsWrapper"
                xs={12}
                sx={{
                  height: isTagsOpen ? 50 : 0,
                  overflow: "hidden",
                  mb: isTagsOpen ? 0 : 1,
                }}
              >
                <Tags
                  tagList={tagList}
                  setSelectedTag={setSelectedTag}
                  selectedTag={selectedTag}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: "100%",
                    height: "max-content",
                    boxSizing: "border-box",
                  }}
                >
                  {posts.map((post) => (
                    <Link
                      sx={{
                        textDecoration: "none",
                        color: "#000",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(post.link);
                      }}
                    >
                      <Paper
                        className="post"
                        sx={{
                          width: "100%",
                          height:
                            selectedTag === "전체" || selectedTag === post.tag
                              ? 100
                              : 0,
                          mb:
                            selectedTag === "전체" || selectedTag === post.tag
                              ? 1
                              : 0,
                          overflow: "hidden",
                        }}
                      >
                        <Grid container sx={{ height: "100%" }}>
                          <Grid
                            xs={2}
                            sm={1.5}
                            md={1}
                            sx={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              flexDirection: "column",
                              WebkitAlignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                height: "70%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-end",
                                pb: 1,
                                boxSizing: "border-box",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "clamp(20px, 3vw, 32px)",
                                  color: "#aaa",
                                  fontFamily: "pretendard",
                                }}
                              >
                                #{post.number}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                width: "100%",
                                height: "30%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-start",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#4aaf9b",
                                  fontWeight: "bold",
                                  fontFamily: "pretendard",
                                  fontSize: "clamp(10px, 1.5vw, 16px)",
                                }}
                              >
                                {post.tag}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid xs={10} sm={10.5} md={11}>
                            <Box
                              sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 24,
                                  fontWeight: "bold",
                                  fontFamily: "pretendard",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {post.title}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "pretendard",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                작성일 | {post.date.toLocaleString()}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "pretendard",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                작성자 | {post.author + " " + post.role}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Link>
                  ))}
                </Box>
              </Grid>
              {/* <Grid item xs={12}>
                pagenation
              </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
