import * as React from "react";
import Box from "@mui/material/Box";
import { scroller } from "react-scroll";
import { useRef } from "react";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";

function LayoutWithMenu(props) {
  return (
    <Box className="layoutWithMenu" id={props.containerTag}>
      <Box
        className="menuBox"
        style={{
          "--sticky-top": Array.from(
            props.menuList[1].subMenu,
            (item) => item.scroll_id
          ).includes(props.selected)
            ? "-200px"
            : "0",
        }}
      >
        <List component="nav" aria-labelledby="nested-list-subheader">
          {props.menuList.map((listItem, idx) => (
            <Box key={listItem.category}>
              <ListItemButton
                disableRipple
                className="category"
                // onClick={(event) => listItem.openHandler(!listItem.open)}
                onClick={(event) =>
                  scroller.scrollTo(listItem.subMenu[0].scroll_id, {
                    offset: -25,
                    smooth: true,
                    duration: 700,
                    containerId: props.containerTag,
                  })
                }
              >
                <ListItemText primary={listItem.category} />
              </ListItemButton>
              <Collapse in={listItem.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {listItem.subMenu.map((item, idx) => (
                    <ListItemButton
                      key={idx}
                      disableRipple
                      className="subMenu"
                      selected={props.selected === item.scroll_id}
                      onClick={(event) => {
                        scroller.scrollTo(item.scroll_id, {
                          offset: -25,
                          smooth: true,
                          duration: 700,
                          containerId: props.containerTag,
                        });
                      }}
                    >
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </Box>
          ))}
        </List>
      </Box>

      <Box className="contentBox">{props.children}</Box>
    </Box>
  );
}

export default LayoutWithMenu;
