import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

// I will use...
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkMode from "@mui/icons-material/DarkMode";

import ScrollToTop from "./ScrollToTop";

const drawerWidth = 240;
const navItems = [
  // {
  //   text: "main",
  //   link: "/",
  // },
  {
    text: "학원소개",
    link: "/about",
  },
  {
    text: "커리큘럼",
    link: "/course",
  },
  {
    text: "자료실",
    link: "/archive",
  },
  {
    text: "FAQ",
    link: "/faq",
  },
];

function Header(props) {
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        height: "100vh",
        backgroundColor: "#000",
        color: "#FFF",
        textAlign: "center",
      }}
      className="header"
    >
      <Box
        className="title"
        sx={{ mt: "40px", mb: "30px", cursor: "pointer" }}
        onClick={() => {
          navigate("/");
          ScrollToTop();
        }}
      >
        <img src="/academy_logo.png" alt="logo" style={{ width: "160px" }} />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              id={item.text}
              sx={{ textAlign: "center" }}
              onClick={() => {
                navigate(item.link);
              }}
            >
              <ListItemText
                primary={item.text}
                sx={{ fontFamily: "Pretendard-Bold" }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box className="header">
      <AppBar
        className="nav"
        component="nav"
        sx={{
          height: "60px",
          background: "rgba(255, 255, 255, 1)",
          padding: "0 4%",
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              color: "#000",
              ml: 0,
              mr: 2,
              display: { sm: "none" },
            }}
          >
            <MenuIcon sx={{ fontSize: "24px" }} />
          </IconButton>
          <Button
            className="logo"
            disableRipple
            sx={{
              width: "fit-content",
              height: "80%",
              cursor: "pointer",
              "&:hover": { backgroundColor: "transparent" },
            }}
            onClick={() => {
              navigate("/");
              ScrollToTop();
            }}
          >
            <img
              src="/academy_logo_blue.png"
              alt="logo"
              style={{ height: "50%" }}
            />
          </Button>
          <Box
            className="menu"
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
              width: "max-content",
            }}
          >
            {navItems.map((item) => (
              <Button
                disableRipple
                key={item.text}
                className={props.selected === item.text ? "selected" : ""}
                sx={{
                  height: "1.8em",
                  color: "#000",
                  fontFamily: "Pretendard-SemiBold",
                  fontSize: "16px",
                  m: "25px 2vw",
                  padding: "0 15px",
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onClick={() => {
                  navigate(item.link);
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
