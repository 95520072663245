import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";

import moment from "moment/moment";
import "moment/locale/ko";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "./react-big-calendar.css";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { TryRounded } from "@mui/icons-material";

// const holidayColor = "var(--ait-yellow)";
const holidayColor = "#d31a38";

const scheduleList = [
  {
    title: "23년 봄학기 상담/등록 시작",
    start: "2023-02-18",
    end: "2023-02-19",
  },
  {
    title: "AIT 코딩교육 설명회 (1차)",
    start: "2023-02-25",
    end: "2023-02-26",
  },
  {
    title: "삼일절",
    start: "2023-03-01",
    end: "2023-03-02",
    holiday: true,
    color: holidayColor,
  },
  {
    title: "AIT 코딩교육 설명회 (2차)",
    start: "2023-03-01",
    end: "2023-03-02",
  },
  /* 2023년 봄학기 */
  // {
  //   title: "23년 봄학기 개강",
  //   start: "2023-03-04",
  //   end: "2023-03-10",
  // },
  {
    title: "23년 봄학기 1주차",
    start: "2023-03-04",
    end: "2023-03-10",
  },
  {
    title: "23년 봄학기 2주차",
    start: "2023-03-11",
    end: "2023-03-17",
  },
  {
    title: "23년 봄학기 3주차",
    start: "2023-03-18",
    end: "2023-03-24",
  },
  {
    title: "23년 봄학기 4주차",
    start: "2023-03-25",
    end: "2023-03-31",
  },
  {
    title: "23년 봄학기 5주차",
    start: "2023-04-01",
    end: "2023-04-07",
  },
  {
    title: "23년 봄학기 6주차",
    start: "2023-04-08",
    end: "2023-04-14",
  },
  {
    title: "23년 봄학기 7주차",
    start: "2023-04-15",
    end: "2023-04-21",
  },
  {
    title: "23년 봄학기 8주차",
    start: "2023-04-22",
    end: "2023-04-28",
  },
  {
    title: "23년 봄학기 9주차",
    start: "2023-04-29",
    end: "2023-05-12",
  },
  {
    title: "가족의 달 방학기간",
    start: "2023-05-02",
    end: "2023-05-05",
    holiday: true,
    color: holidayColor,
  },
  {
    title: "어린이날",
    start: "2023-05-05",
    end: "2023-05-06",
    holiday: true,
    color: holidayColor,
  },

  {
    title: "23년 봄학기 10주차",
    start: "2023-05-13",
    end: "2023-05-19",
  },
  {
    title: "23년 봄학기 11주차",
    start: "2023-05-20",
    end: "2023-05-27",
  },
  {
    title: "부처님 오신날",
    start: "2023-05-27",
    end: "2023-05-28",
    holiday: true,
    color: holidayColor,
  },

  /* 2023년 여름학기 */
  {
    title: "23년 여름학기 개강",
    start: "2023-05-30",
    end: "2023-06-05",
  },
  {
    title: "현충일",
    start: "2023-06-06",
    end: "2023-06-07",
    holiday: true,
    color: holidayColor,
  },
];

function Toolbar(props) {
  const { date } = props;

  const navigate = (action) => {
    props.onNavigate(action);
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={navigate.bind(null, "PREV")}>
          <KeyboardArrowLeftIcon />
        </button>
        <span className="rbc-toolbar-label">{`${date.getFullYear()}년 ${
          date.getMonth() + 1
        }월`}</span>
        <button type="button" onClick={navigate.bind(null, "NEXT")}>
          <KeyboardArrowRightIcon />
        </button>
      </span>
    </div>
  );
}

function AcademicCalendar(props) {
  const localizer = momentLocalizer(moment);

  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const [focusedMonth, setFocusedMonth] = useState(currentMonth);

  useEffect(() => {
    // console.log(focusedMonth);
  }, [focusedMonth]);

  return (
    <Box className="academicCalendar">
      <Box className="calendarWrapper">
        <Calendar
          events={scheduleList}
          localizer={localizer}
          views={["month"]}
          formats={{
            dateFormat: (date, culture, localizer) =>
              localizer.format(date, "D", culture),
            dayHeaderFormat: (date, culture, localizer) =>
              localizer.format(date, "M월 D일(ddd)", culture),
          }}
          onNavigate={(newDate) => setFocusedMonth(newDate.getMonth() + 1)}
          components={{
            toolbar: Toolbar,
          }}
          popup={true}
          showNeighboringMonth={false}
          eventPropGetter={(event) => {
            if (event.color) return { style: { backgroundColor: event.color } };
          }}

          // dayPropGetter={(day) => {
          //   // This selects holiday's next day. why?
          //   if (
          //     scheduleList
          //       .filter((item) => item.holiday)
          //       .map((item) => item.start)
          //       .includes(day.toISOString().slice(0, 10))
          //   )
          //     return { style: { backgroundColor: holidayColor } };
          // }}
        />
      </Box>
      <Box className="scheduleWrapper">
        <Typography className="title">학사일정.</Typography>
        <Box className="scheduleBox">
          {scheduleList.map((item) => {
            const startDate = new Date(item.start);
            const endDate = new Date(item.end);
            const duration = endDate - startDate; // 86400000 for one day

            if (startDate.getMonth() + 1 === focusedMonth) {
              const startDateStr = `${
                startDate.getMonth() + 1
              }월 ${startDate.getDate()}일`;

              const endDateStr = `${
                endDate.getMonth() + 1
              }월 ${endDate.getDate()}일`;

              return (
                <Box key={item.title} className="dateBox">
                  <Box className="date">
                    <Typography className="start">{startDateStr}</Typography>
                    {duration != 86400000 && (
                      <Typography className="tilde">~</Typography>
                    )}
                    {duration != 86400000 && (
                      <Typography className="end">{endDateStr}</Typography>
                    )}
                  </Box>
                  <Typography className="text">{item.title}</Typography>
                </Box>
              );
            }
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default AcademicCalendar;
