import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import { useState, useRef, useEffect } from "react";

export default function ClassCard(props) {
  const difficultyRef = useRef();
  const recommendRef = useRef();

  useEffect(() => {
    switch (props.difficulty) {
      case "베이직":
        difficultyRef.current.classList.add("basic");
        break;
      case "프로":
        difficultyRef.current.classList.add("pro");
        break;
      case "스타터":
        difficultyRef.current.classList.add("starter");
        break;
      case "에이스":
        difficultyRef.current.classList.add("ace");
        break;
      case "마스터":
        difficultyRef.current.classList.add("master");
        break;
    }
  }, []);

  return (
    <Box className="classCard">
      <Box className="thumbnailBox">
        <img
          src={props.img}
          alt="클래스 썸네일"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>
      <Box className="cardTitle">
        <Typography className="className group">{props.groupName}</Typography>
        <Typography className="className difficulty" ref={difficultyRef}>
          {props.difficulty}
        </Typography>
      </Box>
      <Box className="cardContent">
        <Typography className="caption">{props.caption}</Typography>
        <Box className="recommendBox">
          {props.recommend.map((recommendItem) => {
            let recommendTag = "";
            switch (recommendItem) {
              case "초등 3~4":
                recommendTag = "elementaryLow";
                break;
              case "초등 5~6":
                recommendTag = "elementaryHigh";
                break;
              case "중고등":
                recommendTag = "middleHigh";
                break;
            }

            return (
              <Typography
                key={recommendItem}
                className={"recommendItem " + recommendTag}
                ref={recommendRef}
              >
                {recommendItem}
              </Typography>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
