import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import Footer from "../components/Footer";
import FAQCard from "./FAQCard";
import { useState, useEffect } from "react";

const FAQlist = [
  {
    tag: "수업",
    question: "몇 살부터 수업 등록이 가능한가요?",
    answer: [
      "에이트 코딩학원은 초등학교 3학년부터 등록 가능합니다🙂",
      "1-2학년 학생은 별도 문의 바랍니다.",
    ],
  },
  {
    tag: "수업",
    question: "어떤 수업을 들어야 하나요?",
    answer: [
      "간단한 설문과 레벨테스트를 통해 학생에게 맞는 수업을 안내해드립니다☺️",
    ],
  },
  {
    tag: "수업",
    question: "코딩이 입시에 도움이 되나요?",
    answer: [
      "컴퓨터공학과 등 코딩관련 학과의 수시 모집과 입학사정관제에서는 꾸준히 관리해온 포트폴리오가 중요한 역할을 합니다.",
      "에이트 코딩학원에서는 교내 동아리 조직부터 교내외 대회 출전, 개인 포트폴리오 관리까지 가능합니다.",
    ],
  },
  {
    tag: "결제",
    question: "수강료 정책은 어떻게 되나요?",
    answer: [
      "에이트 코딩학원의 커리큘럼은 각 11주 과정이며, 등록 시 11주치 수강료를 한 번에 결제합니다.",
      "수강료는 월 20만원 초중반으로, 학년과 과목에 따라 차이가 있을 수 있습니다.",
    ],
  },
  {
    tag: "수업",
    question: "23년 봄학기 기간은 어떻게 되나요?",
    answer: ["23년 봄학기 기간은 3월 4일 ~ 5월 26일입니다🙂"],
  },
  {
    tag: "수업",
    question: "늦게 등록했는데 보강이 가능한가요?",
    answer: [
      "학기 시작 후 3주까지는 등록을 받고 있고 개별 보충 일정을 잡아 놓친 수업 내용을 보강합니다.",
      "단, 개별 보충의 수업시간은 1시간입니다.",
    ],
  },
  {
    tag: "기타",
    question: "학원에 주차 가능한가요?",
    answer: [
      "학원 건물에 주차는 어렵습니다.",
      "인근 공영주차장을 이용해주시면 감사하겠습니다.",
    ],
  },
  {
    tag: "수업",
    question: "영재원(과학고)을 준비하고 싶습니다.",
    answer: ["방문 상담을 신청하시면 보다 자세히 안내드리겠습니다."],
  },
  {
    tag: "수업",
    question: "수업 준비물이 있나요?",
    answer: [
      "프로그래밍에 필요한 개인 노트북을 지참해야 합니다.",
      "단, 개인 노트북이 없는 경우 학원 공용노트북을 대여하여 사용할 수 있습니다.",
    ],
  },
];

export default function FAQs() {
  const [selectedFAQ, setSelectedFAQ] = useState(null);

  useEffect(() => {
    // ???
  }, [selectedFAQ]);

  return (
    <Box className="FAQs">
      {FAQlist.map((item, idx) => (
        <FAQCard
          key={idx}
          idx={idx}
          tag={item.tag}
          question={item.question}
          answer={item.answer}
          selected={selectedFAQ}
          selectedhandler={setSelectedFAQ}
        />
      ))}
    </Box>
  );
}
