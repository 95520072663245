import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import PageSlider from "../components/PageSlider";

import Symbols from "../components/Symbols";
import TrackPreview from "../components/TrackPreview";
import LearningSpace from "../components/LearningSpace";
import Footer from "../components/Footer";
import ProjectPreview from "../components/ProjectPreview";
import AcademicCalendar from "../components/AcademicCalendar";

import SemesterStartSlide from "../components/SemesterStartSlide";
import InstructorSlide from "../components/InstructorSlide";
import CodingClassPresentationSlide from "../components/CodingClassPresentationSlide";
import InstaViewer from "../components/InstaViewer";
import AdultClassOpenedSlide from "../components/AdultClassOpenedSlide";
import MathClassOpenedSlide from "../components/MathClassOpenedSlide";
import BannerSlide from "../components/BannerSlide";

export default function MainPage() {
  return (
    <Box className="pageWrapper">
      <Header selected="main" />
      <Box className="contentWrapper">
        <PageSlider length={1} autoPlay={50}>
          {/* <CodingClassPresentationSlide /> */}
          {/* <MathClassOpenedSlide /> */}
          {/* <AdultClassOpenedSlide /> */}
          <InstructorSlide />
          <InstructorSlide />
          {/* <BannerSlide
            bgPath={"/laptop_summer.mp4"}
            title={["여름학기", "특강 모집"]}
            content={["여름방학 기간 3주, 주 2회 수업", ""]}
            textLink={"http://pf.kakao.com/_UwlFxj/100454985"}
            text={"개설과목 확인: 카카오톡 채널 (바로가기)"}
            buttonLink={"http://pf.kakao.com/_UwlFxj/100454985"}
          />
          <InstructorSlide /> */}
          {/* <BannerSlide
            bgPath={"/laptop_summer.mp4"}
            title={["여름학기", "특강 모집"]}
            content={["여름방학 기간 3주, 주 2회 수업", ""]}
            textLink={"http://pf.kakao.com/_UwlFxj/100454985"}
            text={"개설과목 확인: 카카오톡 채널 (바로가기)"}
            buttonLink={"http://pf.kakao.com/_UwlFxj/100454985"}
          /> */}
          {/* <CodingClassPresentationSlide /> */}
          {/* <AdultClassOpenedSlide /> */}
          {/* <MathClassOpenedSlide /> */}
        </PageSlider>
        <Symbols />
        {/* <TrackPreview /> */}
        <LearningSpace />
        {/* <ProjectPreview /> */}
        {/* <InstaViewer /> */}
        {/* <AcademicCalendar /> */}
      </Box>
      <Footer />
    </Box>
  );
}
