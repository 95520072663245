import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import CourseCard from "./CourseCard";
import { useState, useRef, useCallback, useEffect } from "react";

export default function CourseGroupCard(props) {
  const [selectedItem, setSelectedItem] = useState(null);
  const courseGroupCardRef = useRef();
  let observer;

  const handleScroll = useCallback(([entry]) => {
    if (entry.isIntersecting) {
      props.selectedhandler(props.className);
    }
  }, []);

  useEffect(() => {
    if (courseGroupCardRef.current) {
      observer = new IntersectionObserver(handleScroll, { threshold: 0.7 });
      observer.observe(courseGroupCardRef.current);

      return () => observer && observer.disconnect();
    }
  }, [handleScroll]);

  return (
    <Box className={props.className} ref={courseGroupCardRef}>
      <Box className="title">
        <Box className="titleBox">
          <Typography>{props.title}</Typography>
          <Box />
        </Box>
        {props.caption.map((item, idx) => (
          <Typography key={idx}>{item}</Typography>
        ))}
      </Box>
      {props.courseList.map((item, idx) => (
        <CourseCard
          key={idx}
          idx={idx}
          title={item.title}
          caption={item.caption}
          img={item.img}
          selected={selectedItem}
          selectedhandler={setSelectedItem}
        />
      ))}
    </Box>
  );
}
