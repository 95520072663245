import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import ScrollToTop from "./ScrollToTop";

import { useNavigate } from "react-router-dom";

import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

const sitemap = [
  {
    category: "학원소개",
    subpages: [
      { name: "브랜드 소개", link: "/about", scroll_id: "brandInfo" },
      { name: "강사진", link: "/about", scroll_id: "instructors" },
      { name: "학습공간 소개", link: "/about", scroll_id: "spaceGallery" },
      { name: "오시는 길", link: "/about", scroll_id: "wayToCome" },
    ],
  },
  {
    category: "커리큘럼",
    subpages: [
      { name: "추천 코스", link: "/course", scroll_id: "elementary" },
      { name: "클래스", link: "/course", scroll_id: "scratch" },
    ],
  },
  {
    category: "자료실",
    subpages: [
      { name: "공지사항", link: "/archive", scroll_id: null },
      // { name: "학생 작품", link: "/archive", scroll_id: null },
      // { name: "Youtube", link: "/archive", scroll_id: null },
      // { name: "Tech Blog", link: "/archive", scroll_id: null },
      // { name: "보도자료", link: "/archive", scroll_id: null },
    ],
  },
  {
    category: "고객지원",
    subpages: [
      { name: "FAQ", link: "/faq", scroll_id: null },
      // {
      //   name: "교습비 게시표",
      //   newWindow:
      //     "https://drive.google.com/file/d/1soJD-ff7QT1oJ6j0Z6xDeLoIY32q7kol/view?usp=sharing",
      //   scroll_id: null,
      // },
      // { name: "이용약관", link: "/faq", scroll_id: null },
      // { name: "교습비 반환기준", link: "/faq", scroll_id: null },
      // { name: "개인정보취급방침", link: "/faq", scroll_id: null },
    ],
  },
];

function Footer(props) {
  const navigate = useNavigate();

  return (
    <Box className="footer">
      <Box className="titleWrapper">
        <Box
          className="title"
          onClick={() => {
            navigate("/");
            ScrollToTop();
          }}
        >
          <img src="/academy_logo.png" alt="logo" style={{ width: "200px" }} />
        </Box>
        <Typography className="companyNum">
          서울시 성동구 성수일로 20 동일빌딩 3층 | 사업자등록번호: 144-81-38601
          | 학원등록번호: 제2521호
        </Typography>
        <Typography className="copyright">
          Copyright © AIT STUDIO Inc. All rights reserved.
        </Typography>
      </Box>
      <Box className="infoWrapper">
        <Box className="share">
          <Typography className="title">바로가기</Typography>
          <Box className="links">
            <Link
              onClick={() => {
                window.open("http://pf.kakao.com/_UwlFxj/chat");
              }}
            >
              <img
                className="icon kakaotalk"
                // src="/icon_kakaotalk.svg"
                src="/Kakao Talk Channel_Logo_Simplified.png"
                alt="카카오톡 채널 아이콘"
                style={{
                  width: "40px",
                  height: "40px",
                  marginTop: "2px",
                  filter: "invert(100%) blur(0.5px)",
                }}
              />
            </Link>
            <Link
              onClick={() => {
                window.open("https://www.instagram.com/aitcoding/");
              }}
            >
              <InstagramIcon className="icon instagram" />
            </Link>
          </Box>
        </Box>
        {sitemap.map((item) => {
          return (
            <Box key={item.category} className="category">
              <Typography className="title">{item.category}</Typography>
              <Box className="subpageWrapper">
                {item.subpages.map((item) => {
                  return (
                    <Link
                      key={item.name}
                      className="subpage"
                      onClick={() => {
                        if (item.newWindow) {
                          window.open(item.newWindow, "_blank");
                        } else {
                          navigate(item.link, {
                            state: { scroll_id: item.scroll_id },
                          });
                          ScrollToTop();
                        }
                      }}
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default Footer;
