import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { scroller } from "react-scroll";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function SemesterStartSlide() {
  return (
    <>
      <Box className="background">
        <video autoPlay loop muted playsInline className="bgVideo">
          <source
            src={"/little_girl_studying_with_computer.mp4"}
            type="video/mp4"
          />
        </video>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            position: "absolute",
          }}
        />
      </Box>
      <Box className="slide semesterStart">
        <Box className="captionBox">
          <Box className="titleBox">
            <Typography className="title">여름학기</Typography>
            <Typography className="title">모집 안내</Typography>
          </Box>
          <Box className="contentBox">
            <Box>
              <Typography className="content">
                여름학기 기간: 5월 30일 ~ 8월 22일
              </Typography>
              <Typography className="content"></Typography>
              <Typography
                className="content"
                style={{
                  fontFamily: "Pretendard-Bold",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open("http://pf.kakao.com/_UwlFxj/chat");
                }}
              >
                상담 예약: 카카오톡 채널 (바로가기)
              </Typography>
            </Box>
            <Button
              disableRipple
              className="arrowButton"
              onClick={() => {
                // scroller.scrollTo("academicCalendar", {
                //   offset: -60,
                //   smooth: true,
                //   duration: 700,
                // });
                window.open("http://pf.kakao.com/_UwlFxj/chat");
              }}
            >
              <ArrowForwardIcon
                className="icon"
                sx={{
                  color: "#FFF",
                  fontSize: "40px",
                }}
              />
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SemesterStartSlide;
