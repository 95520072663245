import Box from "@mui/system/Box";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

import LayoutWithMenu from "../components/LayoutWithMenu";
import Courses from "../components/Courses";
import Classes from "../components/Classes";

export default function CoursePage() {
  const [openCourse, setOpenCourse] = useState(true);
  const [openClass, setOpenClass] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState("elementary");
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scroll_id) {
      scroller.scrollTo(location.state.scroll_id, {
        offset: -25,
        smooth: true,
        duration: 700,
        containerId: "courseContentContainer",
      });
      // console.log(location.state.scroll_id);
    }
  }, [location]);

  useEffect(() => {
    // console.log("openCourse: " + openCourse);
  }, [openCourse]);

  useEffect(() => {
    // console.log("openClass: " + openClass);
  }, [openClass]);

  useEffect(() => {
    // console.log("selectedMenu: " + selectedMenu);
  }, [selectedMenu]);

  return (
    <Box className="pageWrapper">
      <Header selected="커리큘럼" />
      <Box className="contentWrapper">
        <LayoutWithMenu
          containerTag="courseContentContainer"
          menuList={[
            {
              category: "학령별 추천 코스",
              subMenu: [
                {
                  text: "초등학생",
                  scroll_id: "elementary",
                },
                {
                  text: "중고등학생",
                  scroll_id: "middleHigh",
                },
              ],
              open: openCourse,
              openHandler: setOpenCourse,
            },
            {
              category: "과목별 상세 소개",
              subMenu: [
                {
                  text: "스크래치",
                  scroll_id: "scratch",
                },
                {
                  text: "마이크로비트",
                  scroll_id: "microbit",
                },
                {
                  text: "앱인벤터",
                  scroll_id: "appinventor",
                },
                {
                  text: "러플",
                  scroll_id: "rur-ple",
                },
                {
                  text: "파이썬",
                  scroll_id: "python",
                },
                {
                  text: "아두이노",
                  scroll_id: "arduino",
                },
                {
                  text: "물화생지 아두이노",
                  scroll_id: "steam-arduino",
                },
                {
                  text: "C언어",
                  scroll_id: "c-language",
                },
              ],
              open: openClass,
              openHandler: setOpenClass,
            },
          ]}
          selected={selectedMenu}
          selectedhandler={setSelectedMenu}
        >
          <Courses selectedhandler={setSelectedMenu} />
          <Classes selectedhandler={setSelectedMenu} />
        </LayoutWithMenu>
      </Box>
      <Footer />
    </Box>
  );
}
