import React, { useEffect } from "react";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import InstagramIcon from "@mui/icons-material/Instagram";
import CallIcon from "@mui/icons-material/Call";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

const { kakao } = window;

const MapContainer = () => {
  useEffect(() => {
    var container = document.getElementById("myMap");
    var options = {
      center: new kakao.maps.LatLng(37.542272768832255, 127.04904352887036),
      level: 5,
    };
    var map = new kakao.maps.Map(container, options);

    var markerPosition = new kakao.maps.LatLng(
      37.542272768832255,
      127.04904352887036
    );
    var marker = new kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(map);

    kakao.maps.event.addListener(marker, "click", function () {
      window.open("https://naver.me/G3hGUZlU");
    });
  }, []);

  return (
    <Box className="layoutSubPage mapContainer">
      <Box className="title">
        <Box className="titleBox">
          <Typography>오시는 길</Typography>
          <Box />
        </Box>
      </Box>

      <Grid container columnSpacing={2} className="contentBox">
        <Grid
          item
          xs={12}
          sm={6}
          id="myMap"
          style={{
            minHeight: "400px",
          }}
        />
        <Grid item xs={12} sm={6} className="info">
          <Box className="item">
            <Typography className="itemTitle">주소 및 연락처</Typography>
            <Typography>
              서울 성동구 성수일로 20 동일빌딩 3층 (공영주차장 이용)
            </Typography>
            <Typography className="smallCaption">
              화~금 13:00 ~ 22:00 / 토 09:00 ~ 18:00 / 일, 월 휴무
            </Typography>
            <Link
              className="infoWithIcon"
              onClick={() => {
                window.open("https://www.instagram.com/aitcoding/");
              }}
            >
              <InstagramIcon />
              <Typography>@aitcoding</Typography>
            </Link>
            <Link
              className="infoWithIcon"
              onClick={() => {
                window.open("http://pf.kakao.com/_UwlFxj/chat");
              }}
            >
              <img
                className="icon kakaotalk"
                src="/Kakao Talk Channel_Logo_Simplified.png"
                alt="카카오톡 채널 아이콘"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
              <Typography>에이트 코딩학원</Typography>
            </Link>
            <Box className="infoWithIcon">
              <CallIcon />
              <Typography>010-2760-1732</Typography>
            </Box>
            {/* <Typography className="smallCaption">
              미리 연락하고 방문하시면 더 편하게 상담하실 수 있습니다.
            </Typography> */}
          </Box>
          <Box className="item">
            <Typography className="itemTitle">지하철역</Typography>
            <Box className="transport">
              <Typography className="tag lineSB">수인분당</Typography>
              <Typography>서울숲역</Typography>
              <Typography>1번 출구</Typography>
              <Typography>도보 5분</Typography>
            </Box>
            <Box className="transport">
              <Typography className="tag lineNo2">2</Typography>
              <Typography>뚝섬역</Typography>
              <Typography>5번 출구</Typography>
              <Typography>도보 6분</Typography>
            </Box>
            <Box className="transport">
              <Typography className="tag lineNo2">2</Typography>
              <Typography>성수역</Typography>
              <Typography>4번 출구</Typography>
              <Typography>도보 9분</Typography>
            </Box>
          </Box>
          <Box className="item">
            <Typography className="itemTitle">버스정류장</Typography>
            <Box className="transport">
              <Typography className="tag bus">마을</Typography>
              <Typography>성동13</Typography>
              <Typography>경일중고등학교</Typography>
              <Typography>정류장</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MapContainer;
