import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function PageTitle(props) {
  return (
    <Box className="pageTitle">
      <Typography className="main" data-splitting>
        {props.mainTitle}
      </Typography>
      <Typography className="sub">{props.subTitle}</Typography>
    </Box>
  );
}

export default PageTitle;
