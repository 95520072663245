import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

import ClassGroupCard from "./ClassGroupCard";

const classList = [
  {
    scroll_id: "scratch",
    groupName: "스크래치",
    caption: [
      "교육 현장에서 가장 보편적으로 활용하고 있는 블록코딩 도구입니다.",
      "스크래치를 통해 알고리즘과 자동화의 개념을 익히고 다양한 문제를 해결하며 컴퓨터과학적 사고를 키웁니다.",
    ],
    subClass: [
      {
        difficulty: "베이직",
        recommend: ["초등 3~4", "초등 5~6"],
        type: ["블록코딩"],
        caption: "블록코딩으로 간단한 게임과 애니메이션을 구현합니다.",
        // img: "/class_scratch_basic.png",
        img: "class_scratch.svg",
      },
      {
        difficulty: "프로",
        recommend: ["초등 3~4", "초등 5~6"],
        type: ["블록코딩"],
        caption: "다양한 기능이 복합적으로 작동하는 콘텐츠를 창작합니다.",
        // img: "/class_scratch_pro.png",
        img: "class_scratch.svg",
      },
    ],
  },
  {
    scroll_id: "microbit",
    groupName: "마이크로비트",
    caption: [
      "소프트웨어와 하드웨어가 결합된 작은 크기의 컴퓨터입니다.",
      "블록코딩을 통해 사람이나 실제 세상의 현상과 상호작용하는 장치를 프로그래밍하며 피지컬 컴퓨팅의 세계에 입문합니다.",
    ],
    subClass: [
      {
        difficulty: "베이직",
        recommend: ["초등 3~4", "초등 5~6"],
        type: ["블록코딩", "하드웨어융합"],
        caption: "간단한 하드웨어 제어를 통해 피지컬 컴퓨팅을 이해합니다.",
        img: "/class_microbit_basic.png",
      },
      {
        difficulty: "프로",
        recommend: ["초등 3~4", "초등 5~6"],
        type: ["블록코딩", "하드웨어융합"],
        caption: "간단한 알고리즘을 탑재한 자율주행 미니 RC카를 만듭니다.",
        // img: "/class_microbit_pro.png",
        img: "/class_microbit_basic.png",
      },
    ],
  },
  {
    scroll_id: "appinventor",
    groupName: "앱인벤터",
    caption: [
      "MIT가 운영하는 안드로이드 앱 개발도구입니다.",
      "간단한 블록코딩을 통해 스마트폰에 탑재된 각종 센서를 이해하고, 나만의 서비스와 앱을 만들어봅니다.",
    ],
    subClass: [
      {
        difficulty: "스타터",
        recommend: ["초등 3~4", "초등 5~6", "중고등"],
        type: ["블록코딩"],
        caption: "사용자와 간단한 상호작용을 하는 앱을 개발합니다.",
        img: "/class_appinventor_starter.png",
      },
      {
        difficulty: "에이스",
        recommend: ["초등 3~4", "초등 5~6", "중고등"],
        type: ["블록코딩"],
        caption: "다양한 컴포넌트와 활용법에 대해 학습합니다.",
        img: "/class_appinventor_starter.png",
      },
      {
        difficulty: "마스터",
        recommend: ["초등 3~4", "초등 5~6", "중고등"],
        type: ["블록코딩", "하드웨어융합"],
        caption: "클라우드 시스템, AI, IoT의 개념을 접목한 앱을 구현합니다.",
        img: "/class_appinventor_starter.png",
      },
    ],
  },
  {
    scroll_id: "rur-ple",
    groupName: "러플",
    caption: [
      "쉬운 버전의 파이썬 언어를 사용해 제공된 로봇 캐릭터를 조종하는 플랫폼입니다.",
      "텍스트코딩을 본격적으로 배우기에 앞서 컴퓨터과학적 사고와 알고리즘, 클린 코딩에 익숙해집니다.",
    ],
    subClass: [
      {
        difficulty: "베이직",
        recommend: ["초등 5~6", "중고등"],
        type: ["텍스트코딩"],
        caption: "파이썬으로 로봇 캐릭터를 조종하며 텍스트코딩을 익힙니다.",
        img: "class_rurple.png",
      },
      {
        difficulty: "프로",
        recommend: ["초등 5~6", "중고등"],
        type: ["텍스트코딩"],
        caption: "문제의 유형을 분석하고 해결하는 알고리즘 작성법을 배웁니다.",
        img: "class_rurple.png",
      },
    ],
  },
  {
    scroll_id: "python",
    groupName: "파이썬",
    caption: [
      "전문적인 컴퓨터공학 지식이나 경험이 없는 학생도 쉽게 배울 수 있는 프로그래밍 언어입니다.",
      "특히나 인공지능 분야에서 널리 활용되어, AI 엔지니어를 꿈꾸는 학생들이 가장 먼저 배워야 할 언어이기도 합니다.",
    ],
    subClass: [
      {
        difficulty: "스타터",
        recommend: ["초등 5~6", "중고등"],
        type: ["텍스트코딩"],
        caption: "파이썬의 기초적인 자료형과 조건문, 반복문을 학습합니다.",
        img: "class_python.png",
      },
      {
        difficulty: "에이스",
        recommend: ["초등 5~6", "중고등"],
        type: ["텍스트코딩"],
        caption: "기초적인 프로그래밍 이론을 고전 게임을 만들며 응용합니다.",
        img: "class_python.png",
      },
      {
        difficulty: "마스터",
        recommend: ["초등 5~6", "중고등"],
        type: ["텍스트코딩", "이론"],
        caption: "각종 알고리즘과 객체 지향 프로그래밍의 기초를 다집니다.",
        img: "class_python.png",
      },
    ],
  },
  {
    scroll_id: "arduino",
    groupName: "아두이노",
    caption: [
      "센서로부터 값을 읽어와 다른 장치를 제어할 수 있는 마이크로컨트롤러입니다.",
      "현실 세계과 상호작용하는 임베디드 시스템을 접하고, 간단한 장치를 만들며 회로의 구성 그리고 작동 원리를 이해합니다.",
    ],
    subClass: [
      {
        difficulty: "베이직",
        recommend: ["초등 5~6", "중고등"],
        type: ["하드웨어융합"],
        caption: "회로의 원리와 센서 사용법을 배워 나만의 장난감을 만듭니다.",
        img: "class_arduino.png",
      },
      {
        difficulty: "프로",
        recommend: ["초등 5~6", "중고등"],
        type: ["하드웨어융합"],
        caption: "고급 센서의 원리를 이해하고 생활에 유용한 장치를 만듭니다.",
        img: "class_arduino.png",
      },
    ],
  },
  {
    scroll_id: "steam-arduino",
    groupName: "물화생지 아두이노",
    caption: [
      "교과서에서 접했던 과학 이론을 센서와 회로로 직접 측정하고 해석합니다.",
      "아두이노 과학실험을 통해 ‘코딩과 세상의 연결성’을 경험하고, 기초과학 지식을 보다 심도있게 이해할 수 있습니다.",
    ],
    subClass: [
      {
        difficulty: "스타터",
        recommend: ["중고등"],
        type: ["하드웨어융합"],
        caption: "초등학교 3~4학년 수준의 과학 지식을 아두이노로 실험합니다.",
        img: "class_steam_arduino.png",
      },
      {
        difficulty: "에이스",
        recommend: ["중고등"],
        type: ["하드웨어융합"],
        caption: "초등학교 5~6학년 수준의 과학 지식을 아두이노로 실험합니다.",
        img: "class_steam_arduino.png",
      },
      {
        difficulty: "마스터",
        recommend: ["중고등"],
        type: ["하드웨어융합"],
        caption: "중학교 수준의 과학 지식을 아두이노로 실험합니다.",
        img: "class_steam_arduino.png",
      },
    ],
  },
  {
    scroll_id: "c-language",
    groupName: "C언어",
    caption: [
      "오랜 역사를 자랑하는 C언어는 프로그래밍 언어의 정석이라 볼 수 있습니다.",
      "퍼포먼스가 뛰어나 실제 대기업에서 제품 개발에 사용하는 C언어를 배우며 실수를 최소화하는 프로그램 작성법을 익힙니다.",
    ],
    subClass: [
      {
        difficulty: "스타터",
        recommend: ["중고등"],
        type: ["텍스트코딩"],
        caption: "C언어의 기초 문법을 익히고 간단한 문제에 응용합니다.",
        img: "class_C.png",
      },
      {
        difficulty: "에이스",
        recommend: ["중고등"],
        type: ["텍스트코딩", "이론"],
        caption: "구조체와 탐색 알고리즘, 정렬 알고리즘에 대해 배웁니다.",
        img: "class_C.png",
      },
      {
        difficulty: "마스터",
        recommend: ["중고등"],
        type: ["텍스트코딩", "이론"],
        caption: "포인터, 자료구조, 파일 입출력의 기초와 활용을 학습합니다.",
        img: "class_C.png",
      },
    ],
  },
];

export default function Classes(props) {
  return (
    <Box className="layoutSubPage class">
      {classList.map((item) => (
        <ClassGroupCard
          key={item.groupName}
          groupName={item.groupName}
          caption={item.caption}
          subClass={item.subClass}
          id={item.scroll_id}
          selectedhandler={props.selectedhandler}
        />
      ))}
    </Box>
  );
}
